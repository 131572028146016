<template>
  <div class="vm-modal">
    <p class="vm-header" v-html="$t('paywall.form.visaMobile.modal2.header')"></p>
    <p class="vm-text" v-html="$t('paywall.form.visaMobile.modal2.listText4')"></p>
    <div class="vm-list ticks">
      <ul>
        <li>
          <div v-html="$t('paywall.form.visaMobile.modal2.listText1')"></div>
          <iframe :src="getAppsLogosIframeSrc()" title="Payment Logos"></iframe>
        </li>
        <li v-html="$t('paywall.form.visaMobile.modal2.listText2')"></li>
        <li v-html="$t('paywall.form.visaMobile.modal2.listText3', { prefix: prefix, phone: getPhoneNumber()})"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VisaHelpModal2',
  computed: {
    ...mapState({
      prefix: state => state.payment.form.visaMobile.prefix,
      phoneNumber: state => state.payment.form.visaMobile.phoneNumber
    })
  },
  methods: {
    getAppsLogosIframeSrc () {
      return process.env.VUE_APP_WIDGET_NEW_VISA_MOBILE_URL + '/apps-logos'
    },
    getPhoneNumber () {
      return this.$options.filters.phone(this.phoneNumber)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/_visamobile-loader.scss";
</style>
